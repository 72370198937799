$main-color: #22a0d6;
$highlight-bg-color: #e4f0fe;
$highlight-bordor-color: #87c1fd;

.wrapper {
    max-width: 560px;
}

.gwrapper {
    max-width: 600px;
}

.sticky {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
    -webkit-backdrop-filter: blur(3px);
    transition: 0.5s ease;
}

.header-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    max-width: 100%;
    position: relative !important;
    background-color: rgba(255, 255, 255, 0.93);
}

.header {
    max-width: 800px;
    width: 100%;
}

.site-header {
    position: fixed;
    border-top: 0px;
    border-bottom: 0px;
    width: 100%;
    z-index: 1000;
}

.site-nav {
    line-height: 56px;
}

.post-title {
    font-size: 38px;
    line-height: 1.15;
    padding-top: 14px;
    padding-bottom: 14px;
    margin: 0 8%;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    color: #303336;
    font-weight: 700;
    text-align: center;
}

.post-teaser {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 18px;
    padding-top: 24px;
    padding-bottom: 24px;
    color: #303336;
    line-height: 1.5;
}

a {
    text-decoration: none;
    color: inherit;
}

a:visited {
    color: $main-color;
}

a:hover {
    color: $main-color;
    text-decoration: none;
}

.post-title a {
    color: #111;
}

.post-title a:hover {
    color: $main-color;
    text-decoration: none;
}

.post-content h2 {
    font-size: 24px;
    line-height: 1.25;
    padding-top: 47px;
    padding-bottom: 23px;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-weight: 700;
    color: #303336;
}

.post-content h3 {
    font-size: 19px;
    line-height: 1.4;
    padding-top: 24px;
    padding-bottom: 24px;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-weight: 700;
    color: #303336;
}

.post-content li {
    font-family: "PTSerifRegular", "ff-tisa-web-pro", "Tisa Pro", Georgia, serif;
    font-size: 18px;
    padding-top: 14px;
}

.post.style-single {
    padding-top: 54px;
    padding-bottom: 54px;
    max-width: 650px;
}

.post.style-list {
    padding-top: 54px;
    padding-bottom: 54px;
    max-width: 650px;
}

.post-content p {
    position: relative;
    font-size: 18px;
    line-height: 1.5;
    font-family: "PTSerifRegular", "ff-tisa-web-pro", "Tisa Pro", Georgia, serif;
    color: #303336;
    margin-bottom: 25px;
}



hr {
    background-color: #dedede;
    height: 1px;
    border: 0;
    max-width: 100%;
}

.rss-subscribe {
    padding-top: 20px;
}

.post-meta-author {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #9399a5;
    font-weight: 400;
    margin-bottom: 20px;
}

.post-meta-readtime {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #9399a5;
    font-weight: 400;
    margin-bottom: 0px;
    padding-bottom: 7px;
}

.post-meta {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #bfbfbf;
    color: rgba(0, 0, 0, 0.25);
    color: #9399a5;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0px;
}

.wrapper ol {
    counter-reset: item;
    font-family: "PTSerifRegular", "ff-tisa-web-pro", "Tisa Pro", Georgia, serif;
    position: relative;
    font-size: 18px;
    line-height: 1.4;
    color: #303336;
}

.wrapper ol li {
    display: block;
}

.wrapper ol li::before {
    content: counter(item) ". ";
    font-family: "PTSerifRegular", "ff-tisa-web-pro", "Tisa Pro", Georgia, serif;
    font-size: 18px;
    counter-increment: item;
    color: $main-color;
    left: -18px;
    position: absolute;
    text-align: right;
}

.wrapper ul {
    font-family: PTSerifRegular, ff-tisa-web-pro, "Tisa Pro", Georgia, serif;
    position: relative;
    font-size: 18px;
    line-height: 1.4;
    font-family: PTSerifRegular, ff-tisa-web-pro, "Tisa Pro", Georgia, serif;
    color: #303336;
    list-style: none;
}

.wrapper ul li::before {
    content: "•";
    color: $main-color;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

pre,
code {
    font-size: 15px;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    background-color: #d7e7f8;
}

.highlighter-rouge .highlight {
    background: rgb(255, 250, 238);
}

.page-content {
    padding-top: 120px;
    padding-bottom: 108px;
}

.a,
.document a {
    color: $main-color;
    text-decoration: none;
    cursor: pointer;
}

.container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: space-around !important;
    width: 90%;
    margin: 0 auto;
    max-width: 800px;
    -webkit-backdrop-filter: blur(3px);
    transition: 0.5s ease;
    background-color: rgba(255, 255, 255, 0.93);
}

.footer {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 20px;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 14px;
    line-height: 1.2;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-column {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 18px;
    width: 33%;
    text-align: center;
}

.footer-headline {
    margin-bottom: 6px;
    font-weight: 700;
    color: rgba(4, 15, 26, 0.4);
}

.footer-link {
    display: block;
    margin: 3px 0;
    color: rgba(4, 15, 26, 0.4);
}

a:visited.footer-link {
    color: rgba(4, 15, 26, 0.4);
}

a:hover.footer-link {
    color: $main-color;
}

.relatedarticles-item a:hover {
    color: $main-color;
}

.footer-item {
    display: list-item;
    text-align: -webkit-match-parent;
}

.footer-item {
    margin-left: 0px;
}

.footer-list {
    margin-left: 0px;
    list-style: none;
}

.footer-row {
    zoom: 1;
    clear: both;
    margin-left: -15px;
    margin-right: -15px;
}

.rss-subscribe {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #bfbfbf;
    color: rgba(0, 0, 0, 0.25);
    color: #9399a5;
    text-align: center;
    font-weight: 400;
    margin-bottom: 0px;
}

.cagtegory-listing {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue";
    padding-bottom: 5px;
}

.date_addon {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #bfbfbf;
    color: rgba(0, 0, 0, 0.25);
    color: #9399a5;
}

.page_meta {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #646769;
}

.page__taxonomy-item {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #646769;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 8px;
    padding: 5px 10px;
    text-decoration: none;
    border: 1px solid #b6b6b6;
    border-radius: 4px;
}

.dashboard {
    float: left;
    zoom: 1;
    clear: both;
    margin-left: -24px;
    margin-right: -24px;
    width: 100%;
}

.dashboard-block {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 48px;
    padding-left: 1.25em;
}

@media screen and (max-width: 605px) {
    .dashboard-block {
        width: 100%;
        margin-bottom: 3em;
    }
}

.dashboard-list {
    list-style: disc;
    padding-left: 0.2em;
}

.dashboard-header {
    display: block;
    padding-bottom: 12px;
    margin-bottom: 8px;
    padding-top: 30px;
    position: relative;
    border-bottom: 1px solid rgba(38, 52, 74, 0.2);
    font-weight: 700;
    line-height: 1;
}

.dashboard-articles {
    list-style: disc;
    padding-left: 3px;
    margin-left: 0px;
}

.dashboard-article {
    padding: 0.2em 0;
    line-height: 1.3;
    color: $main-color;
}

.dashboard-article a {
    color: #303336;
    font-size: 16px;
    line-height: 1.4;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
}

#search-input {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5em 0.5em 0.5em 35px;
    margin: 0;
    font-size: 1.25em;
    line-height: inherit;
    color: hsla(210, 100%, 1%, 0.81);
    background: hsla(216, 100%, 14%, 0.08);
    border-radius: 0.3em;
    border: 0;
    transition: background 0.1s ease-out;
}

#search-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    max-width: 560px;
}

#search-block {
    position: relative;
    line-height: 1.25;
    width: 100%;
}

#results-container {
    margin-left: 0px;
    padding-bottom: 40px;
}

.search-articles {
    list-style: none;
    padding-left: 16px;
    margin-left: 0px;
    position: relative;
    box-sizing: border-box;
    padding: 0.6em 24px 0.6em 24px;
    line-height: 1.3;
    background-size: 24px 24px;
    background-position: 6px 8px;
    cursor: pointer;
    border-radius: 0.5em;
    margin-bottom: 2px;
}

.search-articles:hover {
    list-style: none;
    padding-left: 16px;
    margin-left: 0px;
    position: relative;
    box-sizing: border-box;
    padding: 0.6em 24px 0.6em 24px;
    line-height: 1.3;
    background-size: 24px 24px;
    background-position: 6px 8px;
    cursor: pointer;
    background-color: $highlight-bg-color;
    border-radius: 0.5em;
    margin-bottom: 2px;
}

.search-list {
    list-style: none;
    padding-left: 0em;
}

.search-article {
    padding: 0.2em 0;
    line-height: 1.3;
    color: #000;
}

.search-teaser {
    padding: 0.2em 0;
    line-height: 1.3;
    font-size: 12px;
    color: #7b7b7b;
    padding-left: 15px;
}

#searchicon {
    position: relative;
    z-index: 1;
    left: 10px;
    top: -32px;
    color: #7b7b7b;
    font-size: 1.1em;
}

#post-icon {
    position: relative;
    z-index: 1;
    left: -10px;
    color: #7b7b7b;
}

body,
input,
textarea {
    -webkit-font-smoothing: antialiased;
    background-color: #fff;
}

.form-page {
    background-color: #f1f2f4;
    padding-bottom: 1px;
}

.contactform {
    margin-bottom: 21px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    color: #292929;
    -webkit-flex-flow: row wrap;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
    max-width: 560px;
}

.contactform-label {
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    color: #545454;
}

.contactform-question {
    padding-top: 15px;
}

.contactform-textfield {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 11px;
    line-height: normal !important;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 16px;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.15s ease-out;
    border-radius: 0.4em;
}

.contactform-textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 11px;
    line-height: normal !important;
    height: 325px;
    width: 100%;
    margin-bottom: 10px;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 16px;
    line-height: 1.3 !important;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.15s ease-out;
    border-radius: 0.4em;
}

.contact-button {
    text-align: center;
    color: #fff;
    background: $main-color;
    border: 1px solid transparent;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: box-shadow 0.1s ease-out;
    -moz-transition: box-shadow 0.1s ease-out;
    -o-transition: box-shadow 0.1s ease-out;
    transition: box-shadow 0.1s ease-out;
    padding: 8px 1.5em;
    font-size: 18px;
}

.contactform-actions {
    text-align: center;
    margin: 40px 0;
}

.form-status {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
}

.message-highlight {
    font-size: 15px;
    border-radius: 3px;
    background-color: #d7e7f8;
}

.contact-button {
    cursor: pointer;
    -webkit-appearance: button;
}

.contact-button:hover {
    background: #3160a7;
    text-decoration: none;
}

.button {
    text-align: center;
    color: #000;
    background: #f1f1f1;
    border: 1px solid #dbdbdb;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: box-shadow 0.1s ease-out;
    -moz-transition: box-shadow 0.1s ease-out;
    -o-transition: box-shadow 0.1s ease-out;
    transition: box-shadow 0.1s ease-out;
    padding: 8px 1.5em;
    font-size: 18px;
}

.button:visited {
    color: #000;
}

.button:hover {
    background: $main-color;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.read-article-action {
    text-align: center;
}

.relatedarticles {
    padding: 1em;
    margin-bottom: 25px;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 15px;
    line-height: 1.4;
    border: 1px solid rgba(38, 52, 74, 0.1);
    background: rgba(38, 52, 74, 0.03);
    border-radius: 5px;
}

.document .relatedarticles-heading,
.relatedarticles-heading {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 10px;
    font-weight: 600;
    color: #303336;
}

.document .relatedarticles-item a,
.relatedarticles-item a {
    color: #303336;
}

.document .relatedarticles-list,
.relatedarticles-list {
    list-style: disc;
    padding-left: 0em;
    margin-bottom: 0;
    color: $main-color;
}

.page__taxonomy {
    margin-bottom: 30px;
    margin-top: 30px;
}

blockquote {
    color: #828282;
    letter-spacing: 0px;
    border: 1px solid $highlight-bordor-color;
    background: $highlight-bg-color;
    border-radius: 5px;
    padding: 20px 20px 0px 20px;
    font-style: normal;
}

.new_blockquote p {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 16px;
}

.signup_form {
    color: #111;
    letter-spacing: 0px;
    border: 1px solid #87c1fd;
    background: #e4f0fe;
    border-radius: 5px;
    padding: 15px;
    font-style: normal;
    font-size: 15px;
}

.email {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 9px;
    line-height: normal !important;
    width: 50%;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
        sans-serif;
    font-size: 15px;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.15s ease-out;
    border-radius: 0.4em;
    width: 50%;
    float: left;
}

.subscription_button {
    font-size: 14px;
    padding: 9px;
    margin-left: 5px;
    background-color: $main-color;
    color: #fff;
}

.signup_fields {
    margin-top: 10px;
    display: block;
}